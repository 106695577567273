// Money Functions

import * as con from "../GlobalConstants"
import { round } from "../GlobalFunctions"


export const parseMoney = (moneyString) =>
{

    if(moneyString === undefined || moneyString === null || moneyString === "")
        return(null)

    moneyString = moneyString.replace(",","")
    con.MONEY_SUFFIXES.forEach((sf) => moneyString.replace(sf,""))

    return(parseFloat(moneyString))
}

export const formatMoney = (value, currency, decimals = 0) =>
{
    return(`${round(value, decimals).toLocaleString()} ${currency}`)
}