// TODO - Documentar
import { Text } from '@blueprintjs/core'
import React from 'react'
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets'
import { MARKET_ASSETS, SELECTED_MARKET_ASSET, TV_CODE, COP_ASSET } from '../../GlobalConstants'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { RefMarketValueHook } from '../hooks/MarketValuesHook'
import MarketChart from '../charts/MarketChart'

import "./TradingViewComponents.css"

function CustomRealTimeWidget({ marketValues, alerts }) {

    const marketAssetId = RefApplicationParameterHook(SELECTED_MARKET_ASSET)

    // All Available assets
    const marketAssets = RefMarketValueHook(MARKET_ASSETS)

    const isCOPSelected = (marketAssetId in marketAssets) && 
                          (marketAssets[marketAssetId][TV_CODE] === COP_ASSET[TV_CODE]);

    return (
        <>
          {
            (marketAssetId in marketAssets) ?
              isCOPSelected ?
                <div style={{ height: "70vh", width: "100%" }}>
                  <MarketChart data={marketValues} alerts={alerts} />
                </div>

              :
                // Muestra el widget de TradingView si NO es COP
                <div className="zoomOut" style={{paddingBottom : "5px"}}>
                  <AdvancedRealTimeChart symbol={marketAssets[marketAssetId][TV_CODE]} 
                     theme={"dark"}
                     allow_symbol_change={false}
                     hide_top_toolbar={false}
                     hide_legend={false}
                     range="12M"
                     hide_side_toolbar={false}
                     withdateranges={false}
                     timezone="America/Bogota"
                     locale="es"
                     interval="D"                               
                     studies={[
                       "MASimple@tv-basicstudies",
                       "MAExp@tv-basicstudies",                     
                     ]}
                     autosize 
                  />
                </div>
            : <div>
                <Text>Activo no Encontrado</Text>
              </div>
          }
        </>
         
    )
}

export default CustomRealTimeWidget
