import "./Pages.css"
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

import CustomRealTimeWidget from '../components/tradingViewComponentes/CustomRealTimeWidget'
import CustomTechnicalAnalysisWidget from '../components/tradingViewComponentes/CustomTechnicalAnalysisWidget'
import * as con from "../GlobalConstants"
import { AssetSelectionBar, assetSelectionBarHeight } from "../components/tradingViewComponentes/AssetSelectionBar"
import { RefToken } from '../components/hooks/AuthenticationHook'
import { RefUserAttributesHook } from '../components/hooks/UserAttributesHook'

function MarketsTechnicalAnalysisPage() {

    const [alerts, setAlerts] = useState([])
    const user = RefUserAttributesHook('user');
    const token = RefToken();

    const marketValues = useSelector(state => state[con.STORE]['market_values']['historic_dollar_info']);

    const fetchAlerts = useCallback(async () => {
        if (user && token) {
            try {
                const response = await axios.get(
                    `${con.rest_base_url}${con.transactions_api}${con.usdcop_alerts_null_reached}${user}/`,
                    {
                        headers: {
                            'Authorization': `Token ${token}`,
                        }
                    }
                );
                setAlerts(response.data);
                localStorage.setItem('alerts', JSON.stringify(response.data));
            } catch (error) {
                console.error('Error fetching alerts:', error);
            }
        } else {
            setAlerts([]);
            localStorage.removeItem('alerts');
        }
    }, [user, token]);

    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'alerts') {
                const updatedAlerts = event.newValue ? JSON.parse(event.newValue) : [];
                setAlerts(updatedAlerts);
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (             
        <div>
            <AssetSelectionBar />
            <div className="gridBox" style={{height : (con.USABLE_SCREEN_SIZE - assetSelectionBarHeight)+"vh", paddingLeft : "1vh", paddingRight : "1vh"}}>                
                <div style={{flexBasis : "70%", height : "100%", marginRight : "0.5vh"}}>                
                    <CustomRealTimeWidget marketValues={marketValues} alerts={alerts}/>
                </div>
                <div style={{flexBasis : "30%", marginLeft : "0.5vh"}}>
                    <div className="columnParameterBox">                     
                        <CustomTechnicalAnalysisWidget/>
                    </div>
                </div>               
            </div>  
        </div>            
    )
}

export default MarketsTechnicalAnalysisPage
