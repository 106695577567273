// MarketChart.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { Switch } from '@blueprintjs/core';

function getWeekNumber(d) {
    const date = new Date(d.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    const week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
}

function aggregateData(dataPoints) {
    const open = dataPoints[0].open;
    const close = dataPoints[dataPoints.length - 1].close;
    const high = Math.max(...dataPoints.map(p => p.high));
    const low = Math.min(...dataPoints.map(p => p.low));
    const date = dataPoints[0].date;
    return { date, open, high, low, close };
}

function groupDataByWeek(dataPoints) {
    const groupedData = [];
    let weekData = [];
    let currentWeek = getWeekNumber(dataPoints[0].date);

    dataPoints.forEach(point => {
        const weekNum = getWeekNumber(point.date);
        if (weekNum !== currentWeek) {
            if (weekData.length > 0) {
                groupedData.push(aggregateData(weekData));
                weekData = [];
            }
            currentWeek = weekNum;
        }
        weekData.push(point);
    });

    if (weekData.length > 0) {
        groupedData.push(aggregateData(weekData));
    }

    return groupedData;
}

function groupDataByMonth(dataPoints) {
    const groupedData = [];
    let monthData = [];
    let currentMonth = dataPoints[0].date.getMonth();
    let currentYear = dataPoints[0].date.getFullYear();

    dataPoints.forEach(point => {
        const monthNum = point.date.getMonth();
        const yearNum = point.date.getFullYear();
        if (monthNum !== currentMonth || yearNum !== currentYear) {
            if (monthData.length > 0) {
                groupedData.push(aggregateData(monthData));
                monthData = [];
            }
            currentMonth = monthNum;
            currentYear = yearNum;
        }
        monthData.push(point);
    });

    if (monthData.length > 0) {
        groupedData.push(aggregateData(monthData));
    }

    return groupedData;
}

function calculateMovingAverage(data, length = 20) {
    const maData = [];
    for (let i = 0; i < data.length; i++) {
        if (i < length) {
            maData.push({ time: data[i].time });
        } else {
            let sum = 0;
            for (let j = 0; j < length; j++) {
                sum += data[i - j].close;
            }
            const maValue = sum / length;
            maData.push({ time: data[i].time, value: maValue });
        }
    }
    return maData;
}

const MarketChart = ({ data, alerts }) => {
    const chartContainerRef = useRef();
    const chart = useRef();
    const priceLines = useRef([]);
    const maSeriesRef = useRef(null);
    const toolTipRef = useRef(null);

    const [interval, setIntervalType] = useState('1D'); // D, S, M
    const [showAlerts, setShowAlerts] = useState(false);
    const [showMA, setShowMA] = useState(false);

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 768 && window.innerWidth <= 1440);

    const [hoveredD, setHoveredD] = useState(false);
    const [hoveredS, setHoveredS] = useState(false);
    const [hoveredM, setHoveredM] = useState(false);

    const [hoveredAlerts, setHoveredAlerts] = useState(false);
    const [hoveredMA, setHoveredMA] = useState(false);

    const getChartHeight = useCallback(() => {
        const baseHeight = Math.min(
            Math.floor(
                window.innerHeight * (isSmallScreen ? 0.22 : isMediumScreen ? 0.3 : 0.4)
            ),
            400
        );
        return Math.floor(baseHeight * 0.95);
    }, [isSmallScreen, isMediumScreen]);

    const formatDataForChart = useCallback((data, intervalType) => {
        let dataPoints = data.dates.map((dateStr, index) => ({
            date: new Date(dateStr),
            open: data.open[index],
            high: data.high[index],
            low: data.low[index],
            close: data.close[index],
        }));
        

        dataPoints.sort((a, b) => a.date - b.date);

        let groupedData = [];
        if (intervalType === '1D') {
            groupedData = dataPoints; 
        } else if (intervalType === '1W') {
            groupedData = groupDataByWeek(dataPoints);
        } else if (intervalType === '1M') {
            groupedData = groupDataByMonth(dataPoints);
        }

        return groupedData.map(candle => ({
            time: Math.floor(candle.date.getTime() / 1000),
            open: candle.open,
            high: candle.high,
            low: candle.low,
            close: candle.close,
        }));
    }, []);

    const updateMASeries = useCallback((show, priceData) => {
        if (!maSeriesRef.current && chart.current) {
            maSeriesRef.current = chart.current.addLineSeries({ color: '#2962FF', lineWidth: 1 });
        }
        const maData = calculateMovingAverage(priceData, 20);
        if (maSeriesRef.current) {
            maSeriesRef.current.setData(maData);
            maSeriesRef.current.applyOptions({ visible: show });
        }
        if (chart.current) {
            chart.current.timeScale().fitContent();
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsSmallScreen(width <= 768);
            setIsMediumScreen(width > 768 && width <= 1440);

            if (chart.current && chartContainerRef.current) {
                chart.current.applyOptions({
                    width: chartContainerRef.current.clientWidth,
                    height: getChartHeight(),
                });
                chart.current.timeScale().fitContent();
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); 

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getChartHeight]);

    useEffect(() => {
        if(!chartContainerRef.current) return;
        const container = chartContainerRef.current;
    
        chart.current = createChart(container, {
            width: container.clientWidth,
            height: container.clientHeight, // esto se ajustará con ResizeObserver
            layout: {
                background: { color: '#131623' }, 
                textColor: '#DDD',
                fontSize: 12,
                fontFamily: 'Arial',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            watermark: {
                visible: true,
                fontSize: 48,
                horzAlign: 'center',
                vertAlign: 'center',
                color: 'rgba(255, 255, 255, 0.1)',
                text: 'L U M E N',
            },
        });
        
        chart.current.priceScale().applyOptions({
            borderColor: '#71649C',
        });
    
        chart.current.timeScale().applyOptions({
            borderColor: '#71649C',
            timeVisible: true,
            secondsVisible: false,
        });
    
        chart.current.candleSeries = chart.current.addCandlestickSeries({
            upColor: 'rgb(50, 168, 82)',
            downColor: 'rgb(225, 50, 85)',
            borderVisible: false,
            wickUpColor: 'rgb(50, 168, 82)',
            wickDownColor: 'rgb(225, 50, 85)',
        });
        
        toolTipRef.current = document.createElement('div');
        toolTipRef.current.style = `
            position: absolute;
            display: none;
            padding: 8px;
            box-sizing: border-box;
            font-size: 12px;
            text-align: left;
            z-index: 1000;
            pointer-events: none;
            border: 1px solid #2962FF;
            border-radius: 2px;
            background: white;
            color: black;
            font-family: Arial;
        `;
        container.appendChild(toolTipRef.current);

        const crosshairMoveHandler = (param) => {
            if (
                !param.point ||
                !param.time ||
                !container ||
                param.point.x < 0 ||
                param.point.x > container.clientWidth ||
                param.point.y < 0 ||
                param.point.y > container.clientHeight
            ) {
                if (toolTipRef.current) {
                    toolTipRef.current.style.display = 'none';
                }
                return;
            }

            const candleData = param.seriesData.get(chart.current.candleSeries);
            if (!candleData) {
                if (toolTipRef.current) {
                    toolTipRef.current.style.display = 'none';
                }
                return;
            }
            const price = candleData.close !== undefined ? candleData.close : candleData.value;
            const date = new Date(param.time * 1000); 
            const dateStr = date.toLocaleString(); 

            if (toolTipRef.current) {
                toolTipRef.current.style.display = 'block';
                toolTipRef.current.innerHTML = `
                    <div style="color:#2962FF">Precio</div>
                    <div style="font-size: 24px; margin: 4px 0px; color:black;">${Math.round(price * 100)/100}</div>
                    <div style="color:black;">${dateStr}</div>
                `;
            }

            const coordinate = chart.current.candleSeries.priceToCoordinate(price);
            if (coordinate === null || !container) return;
            
            let toolTipWidth = 100;
            let toolTipHeight = 80;
            let toolTipMargin = 15;
            
            let x = param.point.x - (toolTipWidth / 2);
            x = Math.max(0, Math.min(container.clientWidth - toolTipWidth, x));

            let y = coordinate - toolTipHeight - toolTipMargin;
            if (y < 0) {
                y = coordinate + toolTipMargin;
            }

            if (toolTipRef.current) {
                toolTipRef.current.style.left = x + 'px';
                toolTipRef.current.style.top = y + 'px';
            }
        };

        chart.current.subscribeCrosshairMove(crosshairMoveHandler);

        const resizeObserver = new ResizeObserver(() => {
            if (!container || !chart.current) return;
            chart.current.applyOptions({ 
                width: container.clientWidth, 
                height: container.clientHeight 
            });
            chart.current.timeScale().fitContent();
        });
        resizeObserver.observe(container);
    
        return () => {
            resizeObserver.disconnect();
            if (chart.current) {
                chart.current.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (!chart.current || !data || !data.dates || data.dates.length === 0) return;
    
        const priceData = formatDataForChart(data, interval);
        if (!chart.current.candleSeries) return;
    
        chart.current.candleSeries.setData(priceData);
    
        if (priceLines.current && priceLines.current.length > 0) {
            priceLines.current.forEach(line => {
                chart.current.candleSeries.removePriceLine(line);
            });
            priceLines.current = [];
        }
    
        if (showAlerts && alerts && alerts.length > 0) {
            alerts.forEach(alert => {
                const level = parseFloat(alert.level);
                const alertTypeText = alert.alert_type === 'below' ? 'Baja' : 'Alza';
                const color = alert.alert_type === 'above' ? '#54aaff' : '#ff4800';
                const labelText = `Alerta: ${alertTypeText} ${alert.level}`;
    
                const priceLine = chart.current.candleSeries.createPriceLine({
                    price: level,
                    color: color,
                    lineWidth: 1,
                    lineStyle: 2,
                    axisLabelVisible: true,
                    title: labelText,
                });
                priceLines.current.push(priceLine);
            });
        }
    
        updateMASeries(showMA, priceData);
    
        if (chart.current && priceData.length > 0) {
            const times = priceData.map(d => d.time).sort((a, b) => a - b);
            const maxTime = times[times.length - 1];
    
            const maxDate = new Date(maxTime * 1000);
            const fourYearsAgo = new Date(maxDate);
            fourYearsAgo.setFullYear(fourYearsAgo.getFullYear() - 4);
            const fromTime = Math.floor(fourYearsAgo.getTime() / 1000);
    
            chart.current.timeScale().setVisibleRange({ from: fromTime, to: maxTime });
        }
    }, [data, interval, showAlerts, alerts, showMA, formatDataForChart, updateMASeries]);
    

    const topLeftContainerStyle = {
        position: 'absolute',
        top: '2%',
        left: '2%',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        zIndex: 10,
    };

    const intervalButtonBaseStyle = {
        border: 'none',
        borderRadius: '4px',
        fontSize: isSmallScreen ? '10px' : '14px',
        padding: isSmallScreen ? '2px 4px' : '4px 8px',
        transition: 'background-color 0.2s',
        cursor: 'pointer',
        color: 'black',
    };

    const DStyle = {
        ...intervalButtonBaseStyle,
        backgroundColor: hoveredD ? 'rgba(238,172,24,0.8)' : 'rgba(238,172,24,0.3)',
    };

    const SStyle = {
        ...intervalButtonBaseStyle,
        backgroundColor: hoveredS ? 'rgba(238,172,24,0.8)' : 'rgba(238,172,24,0.3)',
    };

    const MStyle = {
        ...intervalButtonBaseStyle,
        backgroundColor: hoveredM ? 'rgba(238,172,24,0.8)' : 'rgba(238,172,24,0.3)',
    };

    const switchBaseStyle = {
        margin: 0,
        color: 'white',
        fontSize: isSmallScreen ? '10px' : '14px',
        transition: 'opacity 0.2s',
        background: 'transparent',
        opacity: 0.8,
    };

    const alertsSwitchStyle = {
        ...switchBaseStyle,
        opacity: hoveredAlerts ? 1 : 0.8,
    };

    const maSwitchStyle = {
        ...switchBaseStyle,
        opacity: hoveredMA ? 1 : 0.8,
    };

    return (
        <div style={{ marginTop: '20px', textAlign: 'center', padding: '0 20px', position: 'relative', height: '100%', width: '100%', overflow: 'hidden' }}>
            <div
                ref={chartContainerRef}
                style={{ width: '100%', height: '100%', backgroundColor: '#131623', position: 'relative' }}
            >
                <div style={topLeftContainerStyle}>
                    <button
                        onClick={() => setIntervalType('1D')}
                        onMouseEnter={() => setHoveredD(true)}
                        onMouseLeave={() => setHoveredD(false)}
                        style={DStyle}
                    >
                        D
                    </button>
                    <button
                        onClick={() => setIntervalType('1W')}
                        onMouseEnter={() => setHoveredS(true)}
                        onMouseLeave={() => setHoveredS(false)}
                        style={SStyle}
                    >
                        S
                    </button>
                    <button
                        onClick={() => setIntervalType('1M')}
                        onMouseEnter={() => setHoveredM(true)}
                        onMouseLeave={() => setHoveredM(false)}
                        style={MStyle}
                    >
                        M
                    </button>

                    <Switch
                        checked={showAlerts}
                        onChange={() => setShowAlerts(!showAlerts)}
                        label="Alertas"
                        style={alertsSwitchStyle}
                        onMouseEnter={() => setHoveredAlerts(true)}
                        onMouseLeave={() => setHoveredAlerts(false)}
                    />

                    <Switch
                        checked={showMA}
                        onChange={() => setShowMA(!showMA)}
                        label="MA (20)"
                        style={maSwitchStyle}
                        onMouseEnter={() => setHoveredMA(true)}
                        onMouseLeave={() => setHoveredMA(false)}
                    />
                </div>
            </div>
        </div>
    );
};

export default MarketChart;
